<template>
  <div>
    <profile-search-data-search :search="searchData" :context="currentContext"/>
  </div>
</template>

<script>
const ProfileSearchDataSearch = () => import('./ProfileSearchDataSearch.vue');

export default {
  name: 'ProfileSearchData',
  components: {
    ProfileSearchDataSearch,
  },
  props: {
    data: Object,
    search: {
      name: String,
      taxCode: String,
      startSearch: Boolean,
    },
    context: String,
  },
  computed: {
    searchData() {
      return this.search || {};
    },
    currentContext() {
      return this.$props.context;
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
